import React, { useEffect, useState } from "react";
import styles from "../Object.module.css";
import { Button } from "react-bootstrap";
import ModelGenerator from "./ModelGenerator";
import ObjectPublicService from "../../../services/Objects/ObjectPublicService";
import { IStatus } from "../../../types/common";
import Loading from "../../common/Loading";
import {
  IObjectModel,
} from "../../../types/objects";
import { showModal } from "../../../redux/action-creators/modal";
import { useDispatch } from "react-redux";
import HelperService from "../../../services/HelperService";
import LoadingWrapper from "../../common/LoadingWrapper";
import ObjectModelService from "../../../services/Objects/ObjectModelService";
import ModalInfoService from "../../../redux/services/ModalInfoService";

interface IProps {
  versionId: string | null;
  backToInfo: () => void;
}

function Model({
  versionId,
  backToInfo,
}: IProps) {
  const [status, setStatus] = useState<IStatus>(
    versionId ? IStatus.Loading : IStatus.Success
  );
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [model, setModel] = useState<IObjectModel>({});
  const [modelIsReady, setModelIsReady] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadData = async (id: string) => {
      try {
        const res = await ObjectPublicService.getVersion(id);
        setStatus(IStatus.Success);
        return res;
      } catch (e) {
        setStatus(IStatus.Error);
      }
    };

    if (versionId) {
      loadData(versionId).then((res) => {
        if (res) {
          setModelFiles(res.versionFiles);
        }
      });
    } else {
      setModelIsReady(true);
    }
  }, [versionId]);

  const setModelFiles = async ({ model }: any) => {
    const modelObj: IObjectModel = { rotation: 0 };
    if (await HelperService.isFileExist(model)) modelObj.model = { url: model };
    setModel(modelObj);
    setModelIsReady(true);
  };

  const handleModelChange = (values: { name: string; value: any }[]) => {
    if (!versionId) return;
    ModalInfoService.showModalInfoLoading();
    setHasChanges(true);
    const newModel = { ...model };
    values.forEach((v) => (newModel[v.name] = v.value));
    ObjectModelService.saveIOSModel(
      versionId,
      newModel,
    )
    .then((res) => {
      if (res) {
        ModalInfoService.closeModalInfo();
        backToInfo();
      }
    });

    setModel(newModel);
  };

  const onCancelClick = () => {
    hasChanges
      ? dispatch(
          showModal({
            text: "Go back without saving?",
            primaryAction: backToInfo,
          })
        )
      : backToInfo();
  };

  return (
    <div className={styles.editorContainer}>
      <div className={`d-flex justify-content-center ${styles.editorContent}`}>
        <LoadingWrapper status={status}>
          <>
            <div className={styles.fileContainer}>
              {modelIsReady ? (
                <ModelGenerator model={model} modelChange={handleModelChange} />
              ) : (
                <Loading />
              )}
            </div>
          </>
        </LoadingWrapper>
      </div>
      <div
        className={`${styles.buttonContainer} d-flex justify-content-between`}
      >
        <Button variant="light" size="sm" onClick={onCancelClick}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default Model;
